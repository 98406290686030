import axios from "axios";
import dompurify from "dompurify";
import xss from "xss";
import { debounce, getVehicleName } from "../helpers";

function searchResultsHTML(vehicles) {
  return vehicles.map(vehicle => {
    return `
      <a href="/vehicle/${vehicle.slug}" class="search__result">
        <strong>${dompurify.sanitize(xss.escapeHtml(getVehicleName(vehicle)))}</strong>
      </a>
    `;
  }).join('');
}

function typeAhead(search) {
  if (!search) return;

  const searchInput = search.querySelector("input[name='search']");
  const searchResults = search.querySelector(".search__results");

  function fetch() {
    // if there is no value, quit it!
    if (!this.value) {
      searchResults.style.display = "none";
      return; // stop!
    }

    // show the search results!
    searchResults.style.display = "block";

    axios
      .get(`/api/v1/search?q=${dompurify.sanitize(this.value)}`)
      // .get(`/api/v1/search?q=${this.value}`)
      .then(res => {
        if (res.data.length) {
          searchResults.innerHTML = dompurify.sanitize(searchResultsHTML(res.data));
          return;
        }
        // tell them nothing came back
        searchResults.innerHTML = dompurify.sanitize(`<div class="search__result">No results for <strong class="bg--yellow">${xss.escapeHtml(this.value)}</strong></div>`);
      })
      .catch(err => {
        console.error(err);
      });
  }

  searchInput.on("input", debounce(fetch, 500, false));

  // handle keyboard inputs
  searchInput.on("keyup", (e) => {
    // if they aren't pressing up, down or enter, who cares!
    if (![38, 40, 13].includes(e.keyCode)) {
      return; // nah
    }
    const items = search.querySelectorAll(".search__result");
    const activeClass = "search__result--active";
    const current = search.querySelector(`.${activeClass}`) || items[0];
    current.classList.add(activeClass);
    let next;
    if (e.keyCode === 40 && current) {
      next = current.nextElementSibling || items[0];
    } else if (e.keyCode === 40) {
      next = items[0];
    } else if (e.keyCode === 38 && current) {
      next = current.previousElementSibling || items.at(-1)
    } else if (e.keyCode === 38) {
      next = items[items.length - 1];
    } else if (e.keyCode === 13 && current.href) {
      window.location = current.href;
      return;
    }
    if (current) {
      current.classList.remove(activeClass);
    }
    next.classList.add(activeClass);
  });
}

export default typeAhead;
