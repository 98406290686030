export const getVehicleName = (vehicle) => vehicle.name || `${vehicle.brand} ${vehicle.model} ${vehicle.year}`;

export function debounce(func, wait, immediate) {
  var timeout;
  return function() {
    var context = this, args = arguments;
    clearTimeout(timeout);
    if (immediate && !timeout) func.apply(context, args);
    timeout = setTimeout(function() {
      timeout = null;
      if (!immediate) func.apply(context, args);
    }, wait);
  };
}

export const getRandomInt = (min, max) => Math.floor(Math.random() * (max - min + 1)) + min;

export const getRandomPointInPoland = () => {
  return {
    lat: getRandomInt(49, 54) + Math.random(),
    lng: getRandomInt(17, 24) + Math.random(),
  };
};

export const prepareFlashMessage = (type = "info", message = "") => {
  return `<div class="flash-messages"><div class="flash flash--${type}"><p class="flash__text">${message}</p><button class="flash__remove" onclick="this.parentElement.remove()">×</button></div></div>`
};

export const getGeoCurrentPositionPromise = () => {
  return new Promise((resolve, reject) => {
    navigator.geolocation.getCurrentPosition(
      ({ coords }) => {
        return resolve(coords);
      },
      (error) => {
        return reject(error);
      },
    )
  });
}
