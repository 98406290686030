import axios from "axios";
import { $, $$ } from "./bling";
import { debounce } from "../helpers";
import xss from "xss";
import dompurify from "dompurify";
import * as L from "../libs/leaflet/leaflet";
import "../plugins/mapMarkerCluster/leaflet.markercluster";

const cloudinaryBase = "https://res.cloudinary.com/ao/image/upload";

const getVehicleImageSrc = (vehicle) => {
  const version = vehicle.photoUrl.split("upload")[1];
  return vehicle.photoUrl ? `${cloudinaryBase}/c_fill,w_100,h_100/${version}` : "/uploads/sylwetka-fso-polonez.svg";
};

const prepareVehiclePopUp = (vehicle) => {
  return `
    <div class="popup">
      <div class="popup__data">
        <a href="/vehicle/${dompurify.sanitize(vehicle.slug)}" target="_blank" rel="noopener noreferrer">
          <img src=${getVehicleImageSrc(vehicle)} alt="vehicle photo" />
          <div>Profil → 🔍</div>
        </a>
      </div>
    </div>
  `;
};

const loader = {
  el: $("#loader"),
  messageEl: $("#loader-message"),
  isOn: false,
  message: "",
};

const spinner = {
  el: $("#spinner"),
  isOn: false,
};

const typeFilter = {
  el: $("#types"),
  buttons: $$("img.map__type"),
  types: $$("img.map__type").map(button => button.dataset.type),
  activeTypes: [],
}

const typeFilterProxy = new Proxy(typeFilter, {
  set(target, key, value) {
    if (key === "activeTypes") {
      target.buttons.forEach(button => {
        if (value.includes(button.dataset.type)) {
          button.classList.add("map__type--active");
        } else {
          button.classList.remove("map__type--active");
        }
      });
    }
    return Reflect.set(...arguments);
  },
});

const leftPanel = {
  el: $("#leftPanel"),
  toggleEl: $("#leftPanelToggle"),
  isOpen: true,
};

const mapLoader = new Proxy(loader, {
  set(target, key, value) {
    if (key === "isOn") {
      target.el.style.visibility = value ? "visible" : "hidden";
    }
    if (key === "message") {
      target.messageEl.textContent = dompurify.sanitize(xss.escapeHtml(value));
      target.el.style.visibility = value ? "visible" : "hidden";
    }
    return Reflect.set(...arguments);
  },
});

const spinnerLoader = new Proxy(spinner, {
  set(target, key, value) {
    if (key === "isOn") {
      target.el.style.display = value ? "block" : "none";
    }
    return Reflect.set(...arguments);
  }
});

const searchRadiusBase = 10_000;

const mapOptions = {
  center: { lat: 50.9322828, lng: 17.3133492 }, // Oława Wena
  height: 800,
  zoom: 10,
  radius: searchRadiusBase,
};

const startLocation = {
  lat: 50.9322828,
  lng: 17.3133492,
  coordinates: [17.3133492, 50.9322828],
  latLngCoordinates: [50.9322828, 17.3133492],
};

const finishLocation = {
  lat: 53.883500,
  lng: 21.318570,
  coordinates: [21.318570, 53.883500],
  latLngCoordinates: [53.883500, 21.318570],
};

const markersAlreadyShown = [];
const spinnerKeys = {};
const markerGroups = typeFilterProxy.types.reduce((acc, type) => {
  acc[type] = L.markerClusterGroup();
  return acc;
}, {});

const updateSearchCircle = (circle, lat, lng, zoom) => {
  circle.setLatLng([lat, lng]);
  const oldZoom = mapOptions.zoom;
  if (zoom === oldZoom) return;
  const steps = Math.abs(zoom - oldZoom);
  for (let i = 0; i < steps; i++) {
    mapOptions.radius = zoom < oldZoom ? mapOptions.radius * 2.25 : mapOptions.radius / 2.25;
  }
  mapOptions.zoom = zoom;
  circle.setRadius(mapOptions.radius);
};

const updateTypeLayers = (map, groups) => {
  Object.entries(groups).forEach(([type, group]) => {
    if (typeFilterProxy.activeTypes.length === 0 || typeFilterProxy.activeTypes.includes(type)) {
      map.addLayer(group);
    } else {
      map.removeLayer(group);
    }
  });
};

async function loadPlaces(
  map, {
    lat,
    lng,
    distance,
    loader,
  } = {
    lat: mapOptions.center.lat,
    lng: mapOptions.center.lng,
    distance: mapOptions.radius,
    loader: false,
  }) {
  if (loader) {
    mapLoader.message = "Pobieranie pojazdów...";
  }

  try {
    spinnerKeys[String(lat) + String(lng)] = true;
    spinnerLoader.isOn = true;

    const { data: vehicles } = await axios.get(`/api/v1/vehicles/near?lat=${lat}&lng=${lng}&distance=${distance}`);
    // const { data: vehicles } = await axios.get("/api/v1/vehicles");
    if (!vehicles.length) {
      return;
    }

    const newVehicles = vehicles
      .filter(vehicle => {
        return !markersAlreadyShown.includes(vehicle._id);
      })
      .forEach(vehicle => {
        markersAlreadyShown.push(vehicle._id);

        const [vehicleLng, vehicleLat] = vehicle.location.coordinates;
        const marker = vehicle.photo
          ? L.marker([vehicleLat, vehicleLng], {
            icon: L.divIcon({
              className: "marker",
              html: `<img src=${getVehicleImageSrc(vehicle)} alt="vehicle name" />`,
            })
          })
          : L.marker([vehicleLat, vehicleLng]);
        
        markerGroups[vehicle.type].addLayer(marker.bindPopup(prepareVehiclePopUp(vehicle)));
      });

    updateTypeLayers(map, markerGroups);

    // const markerGroup = L.markerClusterGroup();
    // map.addLayer(markerGroup);
    // const featureGroup = L.featureGroup(markers).addTo(map);
    // map.fillBounds(featureGroup.getBounds());
  } catch (error) {}
  finally {
    spinnerKeys[String(lat) + String(lng)] = false;
    spinnerLoader.isOn = Object.values(spinnerKeys).some(Boolean);
    mapLoader.message = "";
  }
}
const makeLeafletMap = async (mapElement) => {
  if (!mapElement) return;
  
  mapLoader.message = "Ładowanie mapy...";
  const map = L.map(mapElement).fitWorld();
  
  mapLoader.message = "Pobieranie lokalizacji...";
  map.locate({ setView: true, maxZoom: 14 });
  
  L.control.scale().addTo(map);
  map.zoomControl.setPosition("bottomright");

  // default OSM layer
  const osmMap = L.tileLayer('https://tile.openstreetmap.org/{z}/{x}/{y}.png', {
    maxZoom: 14,
    minZoom: 7,
    attribution: '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>'
  }).addTo(map);
  const gMaps = L.tileLayer('http://{s}.google.com/vt/lyrs=m&x={x}&y={y}&z={z}',{
    maxZoom: 14,
    minZoom: 7,
    subdomains:['mt0','mt1','mt2','mt3']
  });

  const startMarker = L.marker(startLocation.latLngCoordinates, {
    icon: L.divIcon({
      className: "marker marker--start",
      html: `<img src="/images/icons/mapStart.svg" alt="Start point" title="Start" />`,
    })
  }).bindPopup('<div class="marker">Start</div>');

  const finishMarker = L.marker(finishLocation.latLngCoordinates, {
    icon: L.divIcon({
      className: "marker marker--finish",
      html: `<img src="/images/icons/mapFinish.svg" alt="Finish point" title="Meta" />`,
    }),
  }).bindPopup('<div class="marker">Meta</div>');

  const searchAreaCircle = L.circle([mapOptions.center.lat, mapOptions.center.lng], {
    color: "tomato",
    fillColor: "transparent",
    fillOpacity: 0.1,
    radius: mapOptions.radius,
  });

  map.on("locationfound", (e) => {
    const { lat, lng } = e.latlng;
    map.setView([lat, lng], map.getZoom());
  });
  map.on("locationerror", () => {
    const { lat, lng } = { lat: mapOptions.center.lat, lng: mapOptions.center.lng };
    map.setView([lat, lng], mapOptions.zoom);
  });

  map.on("moveend", debounce(() => {
    const { lat, lng } = map.getCenter();
    updateSearchCircle(searchAreaCircle, lat, lng, map.getZoom());
    loadPlaces(map, { lat, lng, distance: mapOptions.radius, loader: false });
  }, 0));

  $("#types").on("click", (e) => {
    if (!e.target.matches("img.map__type")) return;

    const type = e.target.dataset.type;
    typeFilterProxy.activeTypes = typeFilterProxy.activeTypes.includes(type)
      ? typeFilterProxy.activeTypes.filter(item => item !== type)
      : [...typeFilterProxy.activeTypes, type];
    updateTypeLayers(map, markerGroups);
  });

  setTimeout(() => {
    [osmMap, gMaps, searchAreaCircle, startMarker, finishMarker].forEach(item => {
      item.addTo(map);
    });
    map.invalidateSize();
  }, 500);
};

export default makeLeafletMap;
