import { $, $$ } from "./modules/bling";
import autocomplete from "./modules/autocomplete";
import typeAhead from "./modules/typeAhead";
import makeLeafletMap from "./modules/leafletMap";
import ajaxHeart from "./modules/heart";

autocomplete($("#address"), $("#lat"), $("#lng"));

typeAhead($(".search"));

setTimeout(() => {
  makeLeafletMap($("#map"));
}, 0);

const heartForms = $$("form.heart");
heartForms.forEach(form => form.on("submit", ajaxHeart));
// heartForms.on("submit", ajaxHeart);

